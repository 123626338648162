import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/workspaces/extie/src/templates/mdx-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About Wrdie`}</h1>
    <p>{`Wrdie is a platform for interactive self expression.`}</p>
    <p>{`It features an innovative drag-and-drop editor which makes it fun and easy to write interactive stories, publish them online, and share them with the community.`}</p>
    <p>{`It is currently in open beta and accepting anyone who wants to join and start writing.`}</p>
    <h2>{`Who made Wrdie?`}</h2>
    <p>{`Wrdie is designed and programmed by Taylor Flagg.`}</p>
    <h2>{`Does it cost anything?`}</h2>
    <p>{`Wrdie is 100% free.`}</p>
    <h2>{`What is the current state of Wrdie?`}</h2>
    <p>{`While it's still early in the development process, so there may be bugs that creep up from time to time, the Wrdie editor should have everything you need to make a fun interactive story and share it with others.`}</p>
    <p>{`Because Wrdie is, and will always be, in constant development. You can visit the `}<a parentName="p" {...{
        "href": "https://productific.com/@Wrdie"
      }}>{`feature vote and request`}</a>{` page to get an idea of future development and to and request and vote on features that you would like to see. You can also follow Wrdie on `}<a parentName="p" {...{
        "href": "https://twitter.com/WrdieOfficial"
      }}>{`Twitter`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.facebook.com/WrdieOfficial"
      }}>{`Facebook`}</a>{` to get updates as they come out. `}</p>
    <h2>{`What is your goal with Wrdie?`}</h2>
    <p>{`I want to grow Wrdie into a major source of online fiction and I'm doing everything I can to get the word out and make people
aware of it.`}</p>
    <p>{`As a platform, Wrdie has a chicken-and-egg problem. It needs writers to create the stories and an audience of readers to consume and encourage
the writers to make more stories.`}</p>
    <p>{`This presents me with a simple goal:`}<strong parentName="p">{` Make Wrdie the best tool on the planet for creating interactive
stories.`}</strong></p>
    <p>{`I believe that if the tools are fun and easy to use, then the
writers will create for the enjoyment of it and the readers will
respond.`}</p>
    <h2>{`How can I help?`}</h2>
    <p>{`There are many ways you can help improve Wrdie:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "discover"
        }}>{` Play `}</a>{` a published story.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sign-in"
        }}>{` Create `}</a>{` your own story.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://discord.gg/mcSKgr7K3C"
        }}>{`Join our discord`}</a>{` to get help and connect with other authors in the Wrdie community.`}</li>
      <li parentName="ul">{`Follow us on `}<a parentName="li" {...{
          "href": "https://twitter.com/WrdieOfficial"
        }}>{`Twitter`}</a>{` or `}<a parentName="li" {...{
          "href": "https://www.facebook.com/WrdieOfficial"
        }}>{`Facebook`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://productific.com/@Wrdie"
        }}>{` Vote `}</a>{` on the features and improvements you want. If you don't see what you're looking for, feel free to suggest your own.`}</li>
      <li parentName="ul">{`Tell your friends and family!`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      